
import React, { useEffect, useState } from 'react';

export default function TreeItem(props) {

    const [language, setLanguage] = useState(props.language);
    const [expanded, setExpanded] = useState(props.expanded);

    useEffect(() => {

    }, []);

    const handleClick = event => {
        if (props.data.children) {
            setExpanded(!expanded);
        } else {
            window.location.pathname = language + props.data.call;
        }
    };

    return (
        <li>
            <a className="TreeItemLabel" onClick={handleClick}>
                {(props.data.children && expanded) &&
                    <span uk-icon="chevron-up"></span>
                }
                {(props.data.children && !expanded) &&
                    <span uk-icon="chevron-down"></span>
                }
                {(!props.data.children) &&
                    <span uk-icon="file-text"></span>
                }
                {props.data.label[language]}
            </a>

            {props.data.children &&
                <ul class="uk-nav-sub">
                {expanded && props.data.children && props.data.children.map((item) => {
                    return <TreeItem key={item.key} language={language} data={item} expanded={true} />
                })}
                </ul>
            }

        </li>
    );
}