
import React, { useEffect, useState, Fragment, createElement } from 'react';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm';
import Axios from 'axios';

import {unified} from 'unified'
import remarkParse from 'remark-parse'
import rehypeSlug from 'rehype-slug'
import remarkRehype from 'remark-rehype'
import rehypeStringify from 'rehype-stringify'
import rehypeReact from 'rehype-react'

export default function Document(props) {

    const [language, setLanguage] = useState(props.language);
    const [content, setContent] = useState();

    useEffect(() => {

        let path = props.path;
        let name = path.split('/').pop();

        Axios.get('https://docs-content.schwarzf.de' + path + '/' + name + '_' + language + '.md').then((response) => {
            unified()
                .use(remarkParse)
                .use(remarkGfm)
                .use(remarkRehype)
                .use(rehypeSlug)
                .use(rehypeReact, {createElement, Fragment})
                .process(response.data)
                .then((file) => {
                    setContent(file.result);
                })
        });

    }, []);

    return (
        <div className="Document sf-document">
            {content}
        </div>
    )
}
