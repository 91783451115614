
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import Toc from 'react-toc';

export default function TOC(props) {
    
    const [language, setLanguage] = useState(props.language);
    const [labels, setLabels] = useState(props.labels);
    const [mdText, setMdText] = useState(props.language);

    useEffect(() => {

        let path = props.path;
        let name = path.split('/').pop();

        Axios.get('https://docs-content.schwarzf.de' + path + '/' + name + '_' + language + '.md').then((response) => {
            setMdText(response.data);
        });

        Axios.get('https://docs-content.schwarzf.de/labels.json').then((response) => {
            setLabels(response.data);
        });

    }, []);
    
    return (
        <div className="TOC" id="tocFrame">
            {labels && labels.toc &&
                <h4>{labels.toc[language]}</h4>
            }

            <Toc markdownText={mdText} type={"raw"} />

        </div>
    );
}