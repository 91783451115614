
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import TreeItem from './TreeItem';

export default function Tree(props) {

    const [language, setLanguage] = useState(props.language);
    const [tree, setTree] = useState();

    function addKeys(keyTree) { // eigentlich sinnlos, weil key nur unter geschwistern unique
        let idx = 0;
        keyTree.forEach(element => {
            element.key = idx;
            idx ++;
            if (element.children) {
                addKeys(element.children);
            }
        });
    }

    useEffect(() => {
        Axios.get('https://docs-content.schwarzf.de/tree.json').then((response) => {
            let data = response.data;
            addKeys(data);
            setTree(data);
        });
    }, []);

    return (
        <ul class="uk-nav uk-nav-default">
            {tree && tree.map((item) => {
                return <TreeItem key={item.key} language={language} data={item} expanded={true} />
            })}
        </ul>
    );
}