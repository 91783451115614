
import React, { useEffect, useState } from 'react';
import Axios from 'axios';

export default function Meta(props) {

    const [language, setLanguage] = useState(props.language);
    const [metadata, setMetadata] = useState();
    const [labels, setLabels] = useState(props.labels);

    useEffect(() => {

        let path = props.path;
        let name = path.split('/').pop();

        Axios.get('https://docs-content.schwarzf.de' + path + '/' + name + '_meta.json').then((response) => {
            setMetadata(response.data);
        });

        Axios.get('https://docs-content.schwarzf.de/labels.json').then((response) => {
            setLabels(response.data);
        });

    }, []);

    return (
        <div className="Meta">
            {labels && labels.metadata &&
                <h4>{labels.metadata[language]}</h4>
            }
            <table class="uk-table uk-table-small uk-table-striped">
                <thead></thead>
                <tbody>
                    {metadata && labels && metadata.title && labels.title &&
                        <tr uk-tooltip={"title: " + labels.title[language] + "; pos: left"}>
                            <td><span uk-icon="file-text"></span></td>
                            <td>{metadata.title[language]}</td>
                        </tr>
                    }
                    {language && labels && labels.lang &&
                        <tr uk-tooltip={"title: " + labels.language[language] + "; pos: left"}>
                            <td><span uk-icon="world"></span></td>
                            <td>{language}</td>
                        </tr>
                    }
                    {metadata && labels && metadata.author && labels.author &&
                        <tr uk-tooltip={"title: " + labels.author[language] + "; pos: left"}>
                            <td><span uk-icon="user"></span></td>
                            <td>{metadata.author}</td>
                        </tr>
                    }
                    {metadata && labels && metadata.updated && labels.updated &&
                        <tr uk-tooltip={"title: " + labels.updated[language] + "; pos: left"}>
                            <td><span uk-icon="pencil"></span></td>
                            <td>{metadata.updated}</td>
                        </tr>
                    }
                    {metadata && labels && metadata.created && labels.created &&
                        <tr uk-tooltip={"title: " + labels.created[language] + "; pos: left"}>
                            <td><span uk-icon="plus-circle"></span></td>
                            <td>{metadata.updated}</td>
                        </tr>
                    }
                </tbody>
                <tfoot></tfoot>
            </table>
        </div>
    );
}