import React, { useState, useEffect } from 'react';
// import './App.css';
import './SchwarzF_Uikit_Custom.css';
import fmds from './fmds.png';
// import './Layout.css';
// import './Components.css';
// import './Document.css';
import Tree from './components/Tree';
import TOC from './components/TOC';
import Document from './components/Document';
import Meta from './components/Meta';
import Axios from 'axios';

function App() {

  const [language, setLanguage] = useState(window.location.pathname.split('/')[1]);
  const [path, setPath] = useState("/" + window.location.pathname.split('/').slice(2).join('/'));

  useEffect(() => {

    document.title = "SchwarzF Docs";

  }, []);

  return (
    <div className="App">
      <div class="uk-navbar-container sf-navbar" uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky">
        <div class="uk-container uk-container-expand">
            <nav class="uk-navbar">
                <div class="uk-navbar-left">
                    <a class="uk-navbar-item uk-logo" href="https://www.schwarzf.de"><span class="sf-logo" uk-tooltip="Landing Page" >
                    <img class="sf-logo-image" src={fmds} alt="FmdS" />
                      </span></a>
                </div>
                <div class="uk-navbar-right">
                    <div class="uk-button-group">
                      <button class="uk-button uk-button-default sf-button" type="button" uk-toggle="target: #offcanvas-tree" uk-icon="icon: table" uk-tooltip="Navigation"></button>
                      <button class="uk-button uk-button-default sf-button sf-responisve-wide-hide" type="button" uk-toggle="target: #offcanvas-info" uk-icon="icon: info" uk-tooltip="Metadata"></button>
                    </div>
                </div>
            </nav>
        </div>
      </div>
      <div id="offcanvas-tree" uk-offcanvas="mode: reveal; overlay: true">
        <div class="uk-offcanvas-bar">
          <Tree language={language} />
        </div>
      </div>
      <div class="uk-container sf-docfield">
        <Document language={language} path={path} />
        <div class="sf-besidedoc sf-responisve-narrow-hide">
          <TOC language={language} path={path} />
          <Meta language={language} path={path} />
        </div>
      </div>
      <div id="offcanvas-info" uk-offcanvas="mode: slide; overlay: true; flip: true">
        <div class="uk-offcanvas-bar">
          <TOC language={language} path={path} />
          <Meta language={language} path={path} />
          <div id="copyright">
              <p>© 2024 Felix Schwarz</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
